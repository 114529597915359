@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'),
    url('../fonts/Montserrat-Black.ttf') format('ttf'),
    url('../fonts/Montserrat-BlackItalic.ttf') format('ttf'),
    url('../fonts/Montserrat-Bold.ttf') format('ttf'),
    url('../fonts/Montserrat-BoldItalic.ttf') format('ttf'),
    url('../fonts/Montserrat-ExtraBold.ttf') format('ttf'),
    url('../fonts/Montserrat-ExtraBoldItalic.ttf') format('ttf'),
    url('../fonts/Montserrat-ExtraLight.ttf') format('ttf'),
    url('../fonts/Montserrat-ExtraLightItalic.ttf') format('ttf'),
    url('../fonts/Montserrat-Italic.ttf') format('ttf'),
    url('../fonts/Montserrat-Light.ttf') format('ttf'),
    url('../fonts/Montserrat-LightItalic.ttf') format('ttf'),
    url('../fonts/Montserrat-Medium.ttf') format('ttf'),
    url('../fonts/Montserrat-MediumItalic.ttf') format('ttf'),
    url('../fonts/Montserrat-Regular.ttf') format('ttf'),
    url('../fonts/Montserrat-SemiBold.ttf') format('ttf'),
    url('../fonts/Montserrat-SemiBoldItalic.ttf') format('ttf'),
    url('../fonts/Montserrat-Thin.ttf') format('ttf'),
    url('../fonts/Montserrat-ThinItalic.ttf') format('ttf');
}